// extracted by mini-css-extract-plugin
export var cardContainer = "podcast-card-module--cardContainer--xrKpi";
export var date = "podcast-card-module--date--D14ip";
export var description = "podcast-card-module--description--xpk3e";
export var divider = "podcast-card-module--divider--7TUIi";
export var imageContainer = "podcast-card-module--imageContainer--70bTp";
export var info = "podcast-card-module--info--6iYbt";
export var podcast = "podcast-card-module--podcast--9PoI4";
export var podcastId = "podcast-card-module--podcastId--b5q70";
export var readmore = "podcast-card-module--readmore--FXau-";
export var title = "podcast-card-module--title--Ze+bR";