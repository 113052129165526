import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as styles from './podcast-card.module.scss';
import AudioPlayer from './podcast-audio-player';

const Podcast = ({ season, seasons }) => {
  const [expandedPodcasts, setExpandedPodcasts] = useState({});

  const toggleText = (podcastId) => {
    setExpandedPodcasts((prev) => ({
      ...prev,
      [podcastId]: !prev[podcastId],
    }));
  };

  const shortenText = (text) => {
    if (text.length <= 300) {
      return text;
    }
    const shortened = text.substr(0, 300);

    const lastDotIndex = shortened.lastIndexOf('.');
    if (lastDotIndex === -1) {
      return shortened + '...';
    }
    const nextChar = shortened.charAt(lastDotIndex + 1);
    if (nextChar === ' ' || nextChar === '\n') {
      return shortened.substr(0, lastDotIndex + 1) + '...';
    } else {
      return shortened + '...';
    }
  };

  return (
    <div className={styles.cardContainer}>
      {season === seasons.title
        ? seasons.podcasts
            .sort((a, b) => b.id - a.id)
            .map((podcast) => (
              <div>
                <div className={styles.podcast}>
                  <div className={styles.imageContainer}>
                    <GatsbyImage image={podcast.image.childImageSharp.gatsbyImageData} alt={podcast.title} />
                  </div>
                  <div className={styles.info}>
                    <p className={styles.description}>
                      {expandedPodcasts[podcast.id] ? podcast.description : shortenText(podcast.description)}

                      <span className={styles.readmore} onClick={() => toggleText(podcast.id)}>
                        {expandedPodcasts[podcast.id] ? 'Afficher moins' : 'Lire la suite'}
                      </span>
                    </p>
                    <h2 className={styles.title}>{podcast.title}</h2>
                    <h3 className={styles.podcastId}>Épisode {podcast.id}</h3>
                    {/* <span className={styles.date}>{podcast.date}</span> */}
                  </div>
                  <div className={styles.audioContainer}>
                    <AudioPlayer key={podcast.id} podcast={podcast} />
                  </div>
                </div>
                <hr className={styles.divider} />
              </div>
            ))
        : null}
    </div>
  );
};

export default Podcast;
