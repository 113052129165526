import React, { useState } from 'react';
import * as styles from './podcast-header.module.scss';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import PodcastCard from './podcast-card';

const PodcastHeader = ({ title, seo, links, seasons }) => {
  const [selectedSeason, setSelectedSeason] = useState(2);
  const [isSelected, setSelected] = useState(false);

  const toggleSeason = (seasonNumber) => {
    setSelectedSeason(seasonNumber);
    setSelected(false);
  };

  const { microphone, femme, apple, spotify, google, deezer, arrowDown } = useStaticQuery(graphql`
    query PodcastPageQuery {
      microphone: file(relativePath: { eq: "podcast/microphone.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      femme: file(relativePath: { eq: "podcast/women.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      apple: file(relativePath: { eq: "podcast/apple.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      spotify: file(relativePath: { eq: "podcast/spotify.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      google: file(relativePath: { eq: "podcast/google.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      deezer: file(relativePath: { eq: "podcast/deezer.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      arrowDown: file(relativePath: { eq: "podcast/arrow-down.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  `);
  return (
    <>
      {/* Desktop */}
      <div className={styles.menu}>
        {seasons.map((season, index) => (
          <h2
            key={index}
            className={`${styles.button} ${
              selectedSeason === season.title ? styles.activeButton : styles.normalButton
            }`}
            onClick={() => toggleSeason(season.title)}
          >
            Saison {season.title}
          </h2>
        ))}
      </div>

      {/* Mobile */}
      <div className={styles.dropdown}>
        <div className={styles.dropdowntitle}>
          <h2 className={styles.dropbtn} onClick={() => setSelected(!isSelected)}>
            {selectedSeason && `Saison ${selectedSeason}`}
          </h2>
          <div onClick={() => setSelected(!isSelected)}>
            <GatsbyImage
              className={styles.arrow}
              image={arrowDown.childImageSharp.gatsbyImageData}
              alt="select a season"
            />
          </div>
        </div>
        <div className={styles.dropdowncontent}>
          {isSelected &&
            seasons.map((season, index) => (
              <h2
                key={index}
                className={`${selectedSeason === season.title ? styles.activeButton : styles.normalButton}`}
                onClick={() => toggleSeason(season.title)}
              >
                Saison {season.title}
              </h2>
            ))}
        </div>
      </div>
      <hr className={styles.divider} />
      {seasons
        .sort((a, b) => b.id - a.id)
        .map((season, key) => (
          <PodcastCard key={key} season={selectedSeason} seasons={season} />
        ))}
    </>
  );
};

export default PodcastHeader;
