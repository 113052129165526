import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/layout';
import PodcastHeader from '../components/podcast/podcast-header';
import Reassurance from '../components/static/rea';
import SEO from '../components/seo';
import HeaderPage from "../components/repeatable/headerPage"

function Podcast() {
  const {
    pagePodcastYaml: { seasons, title,description, seo, links, menu },
  } = useStaticQuery(graphql`
    query {
      pagePodcastYaml {
        title
        description
        seo {
          title
          content
          description
        }
        links {
          apple
          spotify
          google
          deezer
        }
        menu
        seasons {
          title
          podcasts {
            id
            title
            description
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, width: 500, layout: FULL_WIDTH)
              }
            }
            audio_url {
              publicURL
            }
            date
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
      />
      <HeaderPage title={title} description={description}/>
      <PodcastHeader title={title} seo={seo} links={links} seasons={seasons} />
    </Layout>
  );
}

export default Podcast;
